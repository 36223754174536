<template>
  <!-- See: `getFullVh()` -->
  <div style="overflow: hidden; height: 0">
      <div id="measure-vh" style="position: fixed; height: 100vh"></div>
  </div>
  <div class="topcontent-div">
    <div v-show="this.showHome" class="wrapper-todiv" ref="masterDiv" >

      <table width="100%" cellpadding=0 cellspacing=0 border=0>
        <tr v-if="this.showHomePageFromGoogleAd === false">
          <td width="30%" @click="this.doShowTestimonials()" > 
            <img v-if="showImages === true" style="width: 70%; border-radius:10%" alt="Testimonials" src="../assets/5starssm.png"><span class="text-only-button cancel" style="overflow-wrap: break-word;"><br>Testimonials</span>
          </td>
           <td width="40%" >
              <div class="wrapper-todiv" style="height: 10vh">
                <div style="" >
                  <img class="tulogo"  style="height: 7vh;" alt="thankU logo" src="../assets/tutxtlogo.png">
                </div>
                
              </div>
            </td>
          <td width="30%" @click="this.doShowAccount()" style="cursor:pointer;">
            <img v-if="showImages === true" style="width:45%; border-radius:50%"  alt="Account settings" :src="userImageDataComputed"><span class="notesInfoText" style="font-size: clamp(2px, 1.5vh, 12px); overflow-wrap: break-word; "><br>{{ this.loggedInUserDisplayname }}</span>
          </td>
        </tr>
        <!-- <tr v-if="this.showHomePageFromGoogleAd === false">
          <td colspan="3" style="padding-top: clamp(2px, 1.5vh, 12px)">
            
            <span style="font-size: 90%">cashless tipping, <b>no download required!</b></span>
          </td>
        </tr> -->
        <tr v-if="this.showHomePageFromGoogleAd === false" @click="this.doShowSalons();">
          <td colspan="3" style="padding-top: clamp(2px, 2vh, 18px); padding-bottom: clamp(1px, 0.3vh, 7px);">
            <div class="userinfoframe" style="height: clamp(2px, 17vh, 140px); padding-top: clamp(1px, 0.5vh, 14px); padding-bottom: clamp(1px, 0.5vh, 14px);">
              <table border=0 cellspacing=0 cellpadding=0 >
                <tr>
                  <td style="font-size: clamp(8px, 2vh, 14px); color: #000; padding-bottom: 0px; padding-left: clamp(1px, 1vh, 8px); padding-right: clamp(1px, 1vh, 8px)"><b>Find the thankU <h1 style="font-size: 100%; display: inline; color: #000; padding-bottom: 0px; padding-left: 0px; padding-right: 0px">Cashless Tipping app</h1> at</b>
                  </td>
                </tr>
                <tr>
                  <td width=100% style="display: flex; align-items: center; justify-content: space-evenly; padding-top: clamp(1px, 1vh, 8px)">
                    <img v-if="showImages === true" style="width: 22%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px)" alt="Larry King" src="../assets/larrykingbettervert.webp">
                    <img v-if="showImages === true" style="width: 28%; padding-left: clamp(2px, 3vh, 24px)" alt="Josh Wood Colour" src="../assets/jwcsm.webp">
                    <table border=0 cellpadding=0 cellspacing=0 style="display: column; align-items: center;">
                      <tr>
                        <td>
                          <img v-if="showImages === true" style="width: 80%; padding-left: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)" alt="Edward James" src="../assets/edwardjamesvertsm.webp">
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: top; justify-content: top; cursor: pointer">
                          <span @click="this.doShowSalons();" class="cancel" >more...</span>
                        </td>
                      </tr>
                    </table>              
                  </td>
                </tr>
              </table>
            </div>  
          </td>
        </tr>
        
        <tr v-if="this.showHomePageFromGoogleAd === false">
          <td colspan="3">
            
            <!-- <div class="actionbtn-div">
              <button @click="this.doShowGive" class="actionbtn" style="width: 100%; height: clamp(2px, 8vh, 70px); " ref="give-tips">Give Tips</button>
            </div>
             <div class="actionbtn-div">
              <button @click="this.doShowGet" class="actionbtn" style="width: 100%; height: clamp(2px, 8vh, 70px); " ref="give-tips">Get Tips</button>
            </div> -->
            <br>
            <b>Left the salon?</b> <br>You can still tip with the button below
            <div class="actionbtn-div" style="padding-top: 2vh; padding-bottom: 2vh">
              <button @click="this.doShowTipWithoutScanning" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Tip without scanning</button>
            </div>

          </td>
        </tr>
        
         <tr><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)" > 
            <table>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <img v-if="this.showHomePageFromGoogleAd === true" class="tulogo" style="height: 10vh; padding-bottom: 1vh" alt="thankU cashless tipping app" src="../assets/tutxtlogo.png"><br v-if="this.showHomePageFromGoogleAd === true" >
                  <b><span style="font-size: clamp(12px, 5vh, 38px); font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Tipping solved</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 1vh, 2px);">Introducing the most seamless and efficient cashless tipping solution. No app download or tipper registration needed. With a quick scan of the thankU QR code, your clients can leave a tip in 10 seconds or less.
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="actionbtn-div" style="padding-top: 1vh; padding-bottom: 2vh">
                    <button @click="this.doDownloadBrochure" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Download our brochure to learn more</button>
                  </div>

                </td>
              </tr>

              <tr style="cursor:pointer">
                <td @click="doDownloadBrochure();" style="cursor: pointer;">           
                  
                  <img style="width: 90%; cursor:pointer" alt="cashless tipping" src="../assets/introcover.webp">
                
              </td>
              </tr>
                            <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">After reviewing the badge media options below, simply click the 'Get Set Up for FREE' button, let us know the quantities of each format you need, and provide the shipping address – and you're all set!
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">To get you started, we provide a complimentary Front Desk Stand that introduces thankU to your clients. You can then choose between two additional free options listed below.
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0vh"><a v-bind:href="pos2cropped"><img ref="pos2croppedImg" height=47 width=48 style="cursor:pointer" alt="thankU cashless tipping app" v-bind:src="pos2croppedsm"></a>                 
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">In hair and beauty salons, clients often wish to tip multiple people at once—sometimes 2 or even 3. No problem! The 'Tip Again' button is designed specifically for that, making the process incredibly fast and effortless.
                </td>
              </tr>
              <tr v-if="this.showHomePageFromGoogleAd === true" @click="this.doShowSalons();">
                <td colspan="3" style="padding-top: clamp(2px, 2vh, 18px); padding-bottom: clamp(1px, 0.3vh, 7px);">
                  <div class="userinfoframe" style="height: clamp(2px, 15vh, 120px); padding-top: clamp(1px, 0.5vh, 14px); padding-bottom: clamp(1px, 0.5vh, 14px);">
                    <table border=0 cellspacing=0 cellpadding=0 >
                      <tr>
                        <td style="font-size: clamp(8px, 2vh, 14px); color: #000; padding-bottom: 0px; padding-left: clamp(1px, 1vh, 8px); padding-right: clamp(1px, 1vh, 8px); padding-top: 0px"><b>Find the thankU <h1 style="font-size: 100%; display: inline; color: #000; padding-bottom: 0px; padding-left: 0px; padding-right: 0px">Cashless Tipping app</h1> at</b>
                        </td>
                      </tr>
                      <tr>
                        <td width=100% style="display: flex; align-items: center; justify-content: space-evenly; padding-top: clamp(1px, 1vh, 8px)">
                          <img v-if="showImages === true" style="width: 22%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px)" alt="Larry King" src="../assets/larrykingbettervert.webp">
                          <img v-if="showImages === true" style="width: 28%; padding-left: clamp(2px, 3vh, 24px)" alt="Josh Wood Colour" src="../assets/jwcsm.webp">
                          <table border=0 cellpadding=0 cellspacing=0 style="display: column; align-items: center;">
                            <tr>
                              <td>
                                <img v-if="showImages === true" style="width: 80%; padding-left: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)" alt="Edward James" src="../assets/edwardjamesvertsm.webp">
                              </td>
                            </tr>
                            <tr>
                              <td style="text-align: top; justify-content: top; cursor: pointer">
                                <span @click="this.doShowSalons();" class="cancel" >more...</span>
                              </td>
                            </tr>
                          </table>              
                        </td>
                      </tr>
                    </table>
                  </div>  
                </td>

              </tr>
              <tr v-if="this.showHomePageFromGoogleAd === true">
                <td style="height: 10vh;" @click="this.doShowTestimonials()" > 
                  <img v-if="showImages === true" style="width: 30%; border-radius:10%" alt="Testimonials" src="../assets/5starssm.png"><span class="text-only-button cancel" style="overflow-wrap: break-word;"><br>Check out our Testimonials</span>
                </td>
                </tr>
            </table>
          </div>
        </td></tr>
         <tr><td colspan="3" >
          <div class="actionbtn-div notesInfoText" style="">
          </div>
        </td></tr>

        <tr id="howtogetthanku"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">How to get thankU</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 1vh, 24px);">Simply contact us, and we'll provide your thankU QR badge media—currently free of charge.<br><br>We'll also send you an email to forward to your staff, explaining how they can connect to the thankU QR badge in under a minute.<br><br>It's that easy! You can be fully set up by tomorrow, depending on Royal Mail delivery.
                </td>
              </tr>
              <!-- <tr> -->
                <!-- <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0px; padding-bottom: clamp(2px, 3vh, 24px);"> -->
                  <!-- <div class="actionbtn-div"> -->
                    <!-- <button @click="this.doShowGetTipsStart" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Get started</button> -->
                  <!-- </div> -->
                <!-- </td> -->
              <!-- </tr> -->
              <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" ></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
              
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);"><br><b>Option 1: Tabletop Stand</b> - Our Tabletop Stands are designed to be placed directly on the tabletop in front of the client. This discreet option allows clients to see the stand and inquire, "What’s that?"—to which your stylist can respond, "We have clients requesting cashless tipping, and this is our solution." The stand measures 6 x 4.3 cm.
                </td>
              </tr>
              <CountersquareDiv v-if="this.hasScrolled !== false" :VW='this.VW' />

              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 2vh, 24px); padding-bottom: clamp(2px, 0vh, 24px);">Clients can effortlessly scan your thankU QR code, select the person they wish to tip from the list, choose an amount, and pay using Apple Pay, Google Pay (if already set up on their phone), or a credit/debit card. The initial setup may take a few seconds longer, but subsequent transactions are quick and straightforward.</td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 1vh, 24px);"><br><b>Option 2: 'No Glue' Mirror Sticker</b> - easily mount this on a mirror facing the client for greater visibility and impact. No glue is required, and it can be easily removed. The dimensions are 12 cm x 8 cm.
                </td>
              </tr>
              <StickerDiv v-if="this.hasScrolled !== false" :VW='this.VW' />
              <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td></tr>
        
         <tr><td colspan="3" >
          <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"></span></b></p>
        </td></tr>

        <tr id="benefits"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table width=100% border=0>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Benefits</span></b>
              </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Tips direct to staff
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Bypasses the business, HMRC compliant
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Zero management
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Easy, secure and fast
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Superior and fully transparent reporting
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Great support (very rarely needed) – by email and phone
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Happy clients, happy staff, happy management
                </td>
              </tr>
              <!-- <tr> -->
                <!-- <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0px; padding-bottom: clamp(2px, 3vh, 24px);"> -->
                  <!-- <div class="actionbtn-div"> -->
                    <!-- <button @click="this.doShowGetTipsStart" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Get started</button> -->
                  <!-- </div> -->
                <!-- </td> -->
              <!-- </tr> -->
              <tr>
                <td colspan=2>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
              
            </table>
          </div>
        </td></tr>
        
         <tr><td colspan="3" >
          <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"></span></b></p>
        </td></tr>

               
         <tr id="testimonials"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">What our clients say about thankU:</span></b>
              </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">“Our experience has been excellent and we get great feedback from clients and staff” Bobby Collier, Manager, Larry King, South Kensington, London
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">“Amazing app. I use this in my spa in Kent. Both our clients and my team love it. It's simple to use and enables the staff to receive tips direct to their bank accounts, cutting out all the technical faff businesses face when taking tips through their normal payment system. Adrian is also super quick to help if you have any queries!” Elizabeth Dadson, owner, Aquavie Boutique Spa, Kent
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">“I got this sorted for my salon in West London… definitely recommend, really good at getting back to you when setting it up as well, all very fast! HIGHLY RECOMMEND!” Ruby Byrne, owner, Ruby B Organic, London
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“Fantastic way for our team to not miss out on tips in a world where less people are carrying cash. Our clients absolutely love how easy it is too :)” Marcello Ferri, Manager, Collections Hair Club, Weybridge
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“I love the ThankU app ! I’m so glad I found it for my hairdressing salon business. I was always struggling with clients wanting to add a tip for the stylist onto to their bill when paying by card … so to find a solution where the money completely bypassed me as a salon owner was such a relief! I’d 100% recommend the ThankU app- it’s so easy to use and the customer service and direct help you get is amazing. Every salon business should get the ThankU app on their reception desks !” – Victoria Williams, owner, Head Office, Wrexham
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“Our staff and clients love thankU Cashless Tipping, it’s going really well thank you thankU team!” – Aneeqa, salon manager at TONI&GUY Westfield, Stratford City
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“All of the staff and clients are very much enjoying having the ‘thankU’ service 😊” – Tash Bishop, salon manager,  Salon41, Bristol
                </td>
              </tr>
              <tr>
                <td style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">“Duck & Dry has thankU at our Chelsea, Mayfair and Soho sites – clients and staff are very happy, the whole tipping ‘issue’ has just gone away with thankU – and it requires zero management!”  - Rosie Rowley, Salon Group Manager, Duck & Dry, London
                </td>
              </tr>
              <tr>
                <td @click="this.doShowTestimonials()" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(2px, 3vh, 24px); padding-bottom: clamp(2px, 3vh, 24px);">
                  
                  <img v-if="showImages === true" style="width: 30%; border-radius:10%" alt="Testimonials" src="../assets/5starssm.png"><span class="text-only-button cancel" style="overflow-wrap: break-word;"><br><b>see our testimonials and reviews page</b></span>
                </td>
              </tr>
            </table>
          </div>
        </td></tr>
         <tr><td colspan="3" >
          <div class="actionbtn-div notesInfoText" style="">
          </div>
        </td></tr>

        <tr id="features"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table width=100% border=0>
              <tr>
                <td colspan="3" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Features</span></b>
              </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Dashboard and Account screens for detailed info on all your tips
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Tipper not present? You can give them a tipping link (in your automated emails, website, however you want), or they can use the ‘Tip without scanning’ button above, here on the website
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Tipper option to pay transaction fees, which they normally do
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Add your photo, tippers love to see you when they tip
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Suggested tipping amounts also shown in tipper’s own currency, if different
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Automatic emails for tip receipts, for payments to bank accounts, and for tip recipient’s annual report
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Works with Apple Pay and Google Pay, no need for tippers to set up
                </td>
              </tr>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 25%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divr"><img style="width: clamp(20px, 45%, 35px); cursor:none" alt="yes" src="../assets/tick1sm.webp"></td>
                <td colspan="2" style="width: 75%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divl">Payment can also be made by card, simple one time input, then thankU securely remembers for next time (user option)
                </td>
              </tr>
              <!-- <tr> -->
                <!-- <td colspan="3" style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: 0px; padding-bottom: clamp(2px, 3vh, 24px);"> -->
                  <!-- <div class="actionbtn-div"> -->
                    <!-- <button @click="this.doShowGetTipsStart" class="widebtn" style="width: 80%; height: clamp(2px, 7vh, 60px); ">Get started</button> -->
                  <!-- </div> -->
                <!-- </td> -->
              <!-- </tr> -->
              <tr>
                <td colspan=2 style="padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);">thankU is available at <b>no cost to businesses</b> - fees are payable on tip transactions, most often paid by the tipper, but when not, fees are deducted from the tip. For FAQ on receiving tips, please see <span class="text-only-button cancel" @click="doGetTipsShowMoreInfo()" style="pointer: cursor"><b>here</b></span>, and specifically on fees, please see <span class="text-only-button cancel" @click="doGetTipsShowMoreInfoFees()" style="pointer: cursor"><b>here</b></span>. Alternatively, <span class="text-only-button cancel lt-blue" style="pointer: cursor"><b><u><a href="https://www.thanku.app/introtothanku.pdf" target="_self">download our brochure</a></u></b></span> and full fee information is set out on page 12. 
                </td>
              </tr><tr>
                <td colspan=2>
                  <div class="actionbtn-div" style="padding-top: 1vh; width: 95%; margin: auto;">
                    <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }"></span><button class="widebtn" style="width: 100%; height: 7vh;"  @click="this.doOrderBadges();" >Get set up for FREE</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td></tr>
        
        <tr id="features"><td colspan="3" style="padding-top: clamp(2px, 2vh, 18px)">
          <div class="userinfoframe actionbtn-div notesInfoTextLarger" style="padding-bottom: clamp(1px, 1vh, 8px); padding-top: clamp(1px, 1vh, 8px)"> 
            <table width=100% border=0>
              <tr>
                <td colspan="2" style="padding-top: clamp(1px, 1vh, 8px);">
                <b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">Frequently Asked Questions (FAQ)</span></b>
              </td>
              </tr>
              <br>
              <tr>
                <td colspan="1" style="background: #FFFFFF; width: 50%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divc"><span class="text-only-button cancel" @click="doGiveTipsShowMoreInfo()" style="pointer: cursor"><b>FAQ Tippers</b></span></td>
                <td colspan="1" style="width: 50%; padding-left: clamp(2px, 2vh, 18px); padding-right: clamp(2px, 2vh, 18px); padding-top: clamp(1px, 1vh, 8px); padding-bottom: clamp(2px, 3vh, 24px);" class="divc"><span class="text-only-button cancel" @click="doGetTipsShowMoreInfo()" style="pointer: cursor"><b>FAQ Recipients</b></span></td>
              </tr>

            </table>
          </div>
        </td></tr>

         <tr><td colspan="3" >
          <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);"></span></b></p>
        </td></tr>

        <tr>
          <td colspan="3" style="padding-top: clamp(2px, 1.5vh, 12px)">
            <table width="100%">
              <tr>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowContact();">Contact</button>
                </td>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowAbout();">About</button>
                </td>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowTerms();">Terms</button>
                </td>
                <td width="25%">
                  <button class="text-only-button cancel" @click="this.doShowPrivacy();">Privacy</button>
                </td>      
              </tr>
              <tr>
                <td colspan="4" style="padding-top: 0.clamp(2px, 2vh, 18px)">
                  <span style="font-size: 60%; color: #000012">Copyright 2022 Appsanely Limited trading as thankU Cashless Tipping</span>
                </td>
              </tr>
            </table>
          </td>
                         
        </tr>
      </table>
      <LoadParseFromHome :key="loadParseToggle" v-if="this.loadParseToggle !== false" :functionName=functionName :paramsObject=paramsObject @return-parent-json="returnParseJSON"/>
      <!-- <LoadLogRocketFromHome :key="loadLogRocketToggle" v-if="this.loadLogRocketToggle !== false" :functionName=functionName :paramsObject=paramsObject @return-parent-json="returnParseJSON"/> -->
    </div>
    <!-- <router-link to="givetips"> Give Tips</router-link> -->
    
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

import pos2croppedsm from "../assets/pos2croppedsm2.webp";
// import pos2croppedsm from "../assets/pos2croppedsm2.webp";
import pos2cropped from "../assets/pos2cropped.webp";
// import pos2cropped from "../assets/pos2cropped.webp";

const CountersquareDiv = defineAsyncComponent({loader: () =>
  import('../components/CountersquareDiv.vue')}
)

const StickerDiv = defineAsyncComponent({loader: () =>
  import('../components/StickerDiv.vue')}
)



export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  components: {
    CountersquareDiv,
    StickerDiv,
    LoadParseFromHome: defineAsyncComponent(() =>
      import('../components/LoadParseFromHome.vue')
    ),
    // LoadLogRocketFromHome: defineAsyncComponent(() =>
    //   import('../components/LoadLogRocketFromHome.vue')
    // )
  },


  data() {
    return {
      showHome: true,
      loggedInUserDisplayname: "",
      showImages: false,
      pos2croppedsm: pos2croppedsm,
      // pos2croppedsm: pos2croppedsm,
      pos2cropped: pos2cropped,
      // pos2cropped: pos2cropped,
      hasScrolled: false,
      componentKeyTip: false,
      showHomePageFromGoogleAd: false,
      VW: 0,
      loadParseToggle: false,
      // loadLogRocketToggle: false,
      vhMult: 1,
      vhMultFont: 1,
      fontSizeNormal: 2.5, // vh
      fontSizeNormalInPXToUse: "",
      fontSizeNormalLineHeight: 3.3,// vh
      fontSizeNormalInPXLineHeightToUse: "",
    }
  },

  
  computed: {
    userImageDataComputed() {
        if (window.localStorage.getItem("userPhotoFileData") !== null) {
            // so the is we want to ensure we have the correct photo, and if the user switches devices (edge case) that won't be the case. We could easily sort this to check if the filename in the user's record was the same as in the localStorage but that is an expensive call. So compromise is to assume that the user is going to do something - either go to account or make a tip and we will refresh local storage at that point
            // ////console.log("userPhotoFileData::: " + window.localStorage.getItem("userPhotoFileData") );
            // /alert("COMPUTED from localStorage userPhotoFileData: " + window.localStorage.getItem("userPhotoFileData"));
            return window.localStorage.getItem("userPhotoFileData");
        } else {
            // /alert("simply returned accountperson.png");
            return require('@/assets/accountperson.png'); 
        }
    } 
  },
  methods: {
    doOrderBadges(){
      
      let url = "mailto:mailorders@thanku.app?subject=get thankU enquiry&body=Please fill out below - we will email you back to confirm your order and let you know if we need any further information before dispatching it - please respond  below:%0D%0A%0D%0AWhat is your business name?%0D%0A%0D%0AAre you a salon? If not, what type of business please?                  %0D%0A%0D%0AHow many client seats do you have (if salon), or staff (if other)? %0D%0A%0D%0AIn addition to our Front Desk Stand which we send you anyway, would you additionally like either Option 1, our Tabletop Stand or Option 2, our 'No Glue' Mirror Stickers? or neither?                  %0D%0A%0D%0AIf you are not a salon and would like different QR media (e.g. wearables), let us know and we will email you to ask a bit more about your business..                  %0D%0A%0D%0AWhich address should send we send your thankU QR media to?                  %0D%0A%0D%0A%0D%0AThank you! best wishes, the thankU Team";
      
      // console.log("tapped get free thankU badges button on HOME");

      let LRObjectId = undefined;

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      } else if (window.localStorage.getItem("logRocketUserTimeStampId") !== null) {
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      } else {
        window.localStorage.setItem("logRocketUserTimeStampId", new Date().getTime());
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      }
      
      let googleAdsOriginalReferrerURL = window.localStorage.getItem("googleAdsOriginalReferrerURL") !== undefined ? window.localStorage.getItem("googleAdsOriginalReferrerURL") : "n/a";
      let params = {
        toEmail: "appalert@thanku.app",
        subject: "GREAT! Someone tapped get free thankU badges button on HOME" ,
        body: {
          type: "TappedOrderThankUBadges",
          googleAdsOriginalReferrerURL: googleAdsOriginalReferrerURL,
          referrerURL: window.localStorage.getItem("referrerURL"),
          LRObjectId: LRObjectId
        },
      };

      this.loadParse = false;
      this.loadParseToggle = !this.loadParseToggle;
      this.functionName = "sendEmail";
      this.paramsObject = params;
      this.loadParse = true;

      window.open(url);


    },
    doDownloadBrochure(){
      const url = "https://www.thanku.app/introtothanku.pdf"
      // console.log("opening pdf");

      // this.$refs["brochureLink"].style.color = "purple";
      // window.localStorage.setItem("downloadBrochureLinkColor", "purple");

      let LRObjectId = undefined;

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      } else if (window.localStorage.getItem("logRocketUserTimeStampId") !== null) {
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      } else {
        window.localStorage.setItem("logRocketUserTimeStampId", new Date().getTime());
        LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
      }


      let googleAdsOriginalReferrerURL = window.localStorage.getItem("googleAdsOriginalReferrerURL") !== undefined ? window.localStorage.getItem("googleAdsOriginalReferrerURL") : "n/a";
      const params = {
        toEmail: "appalert@thanku.app",
        subject: "GREAT! Someone downloaded thankU pdf brochure FROM HOME PAGE" ,
        body: {
          type: "TappedDownloadThankUBrochure",
          googleAdsOriginalReferrerURL: googleAdsOriginalReferrerURL,
          referrerURL: window.localStorage.getItem("referrerURL"),
          LRObjectId: LRObjectId
        },
      };
      this.loadParse = false;
      this.loadParseToggle = !this.loadParseToggle;
      this.functionName = "sendEmail";
      this.paramsObject = params;
      this.loadParse = true;

      // let params2 = (window.localStorage.getItem("referrerURL") !== null && window.localStorage.getItem("referrerURL") !== undefined && window.localStorage.getItem("referrerURL") !== "") ? window.localStorage.getItem("referrerURL") : "n/a home";
      // this.loadLogRocket = false;
      // this.loadLogRocketToggle = !this.loadLogRocketToggle;
      // this.functionName = "doLoadLogRocket";
      // this.paramsObject = params2;
      // this.loadLogRocket = true;

      setTimeout(() =>  {
        // this gives it time to fire the Parse
        window.open(url, "_self");
      } , 500);

    },
    doGiveTipsShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGiveInfo: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGetTipsShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    doGetTipsShowMoreInfoFees(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true, feesShow: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    resizeImg() {
      // the reason we have done it this way is because 1) we want to have img width and height attributes for Google pagespeed ranking and 2) the approach with using clamp() for the css didn't work even in proportion to img dims because the clamp could let the img expand to different points within the clamp, it was aspect locked - this makes sure we are always aspect locked
    
        // 477 483
        let pos2croppedImg = this.$refs.pos2croppedImg;
        pos2croppedImg.width = this.VW * 0.8;
        pos2croppedImg.height = this.VW * 0.8 / 477 * 483; // the dims of the image 
        // console.log("pos2croppedImg image height is now " + pos2croppedImg.height);
        // this.$forceUpdate(); 
    },

    doShowAccount(){
      
      let returnJSON = { showAccount: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowSalons(){
      ////console.log("YAY!");
      let returnJSON = { showSalons: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowGive(){
      
      let returnJSON = { showGive: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowGet(){
      
      let returnJSON = { showGet: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTipWithoutScanning(){
      // console.log("initiating showTipWithoutScanning on Home");
      let returnJSON = { showTipWithoutScanning: true, fromScreen: "Home" };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
    },
    doShowGetTipsStart(){
    
      
      let returnJSON = { showGetTipsStart: true, fromScreen: "Home" };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back

    },
    doShowContact(){
      
      let returnJSON = { showContact: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowAbout(){
      
      let returnJSON = { showAbout: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTerms(){
      
      let returnJSON = { showTerms: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowPrivacy(){
      
      let returnJSON = { showPrivacy: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    doShowTestimonials(){
      
      let returnJSON = { showTestimonials: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back
      
    },
    setDis(){
      
      if (window.localStorage.getItem("tudis") !== null){ // for some reason it's null not undefined
        // /////console.log("setDis tudis::: " + window.localStorage.getItem("tudis"));
        this.loggedInUserDisplayname = window.localStorage.getItem("tudis");
      }
    },

    doSetMetas(){

      document.title = "thankU Cashless tipping - giving tips with thankU couldn't be simpler - QR-based cashless tipping app, tips go directly to the person you are tipping, used at top salons such as Larry King and Josh Wood Colour - no download required";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Giving tips with thankU Cashless Tipping couldn't be simpler - QR-based cashless tipping app, tips go directly to the person you are tipping, used at top salons such as Larry King and Josh Wood Colour. Just scan the thankU Cashless Tipping QR badge or tap 'Tip without scanning' below and tip in 10 seconds or less. No app download and No account creation required, and tips go directly to the person you are tipping. Secure transaction with Stripe Inc. 1. Scan a thankU QR code 2. Select your tip 3. Pay with Apple Pay, Google Pay, or your card. ") ;

      
    
    },
    setDelayedTitle(){
      document.title = "thankU"; // this is so if you save it to your home screen the proposed title is just 'thankU'
    },
    showImagesNow(){
      this.showImages = true;
    },
    doScrollIsTrue(){
      this.hasScrolled = true;
    },
    setScreenHeightMultiplier(){

      var vhMult;
      let elem = document.getElementById("measure-vh");
      var elemHeight = elem.clientHeight ;
      // window.alert("viewport: " + document.documentElement.clientHeight + " real height: " + window.innerHeight + " or is it " + elemHeight + "?");

      if (document.documentElement.clientHeight >= elemHeight) {
          vhMult = 1;
      } else {
          vhMult = document.documentElement.clientHeight / elemHeight; // will be <1 to multiply with
      }

      // console.log("this.vhMult : " + this.vhMult );
      // window.alert("this.vhMult * elemHeight: " + this.vhMult * elemHeight);

      return vhMult;
    },
    doFontSizeComputed () {
        this.fontSizeNormalInPXToUse = this.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
        this.fontSizeNormalInPXLineHeightToUse = this.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);

    },
    convertVHSizeToMaxPixelsSize(vhSize, maxSizeInPixels){ // local version because can't use shared on home page ref Parse
      const pixelConversionOfVHSize = Math.round(window.innerHeight / (100 / vhSize));
      // we are assuming 100% font size is 16px
      const sizeInPX = maxSizeInPixels;
      var sizeInPXToUse = "";
      if (pixelConversionOfVHSize > sizeInPX) {
        sizeInPXToUse = sizeInPX; // shouldn't be larger than this   
      } else {
        sizeInPXToUse = pixelConversionOfVHSize;
      }
      // /console.log("calc'd size: " + sizeInPXToUse + 'px');
      
      return sizeInPXToUse;
    },
  },
  beforeCreate(){
    const image = new Image();    
    image.src = require('@/assets/bluecirclesm.webp'); 
    // designed to have the same effect as prefetch
  },
  created(){
    ////console.log("Home has been created!" + this.redirect);

    this.showHomePageFromGoogleAd = this.$route.params !== undefined ? this.$route.params.showHomePageFromGoogleAd === 'true': false; // so showHomePageFromGoogleAd is not passed as a prop, it is passed through this.$route.params AND NOTE: though this.showHomePageFromGoogleAd aws passed as a boolean from Homecontent passing it through the router converts the value to a string
    
    console.log("showHomePageFromGoogleAd ON HOME::::" + this.showHomePageFromGoogleAd);


  },
  mounted(){

    ////console.log("Home mounted!");
    // if (window.localStorage.getItem("downloadBrochureLinkColor") !== null) {
    //   this.$refs["brochureLink"].style.color = window.localStorage.getItem("downloadBrochureLinkColor");
    // }

    let elem = this.$refs.masterDiv;
    var rect = elem.getBoundingClientRect();
    this.VW = rect.width;
    // console.log("elemWdith@:: " + this.VW);
    this.resizeImg();


    this.setDis();
    this.doSetMetas();
    this.doFontSizeComputed();
    
    this.vhMult = this.setScreenHeightMultiplier(); // local version because can't access shared on Home without pulling in Parse
    this.vhMultFont = 16 * this.vhMult; // font size normal

    window.setTimeout(this.setDis, 10); // hacky way of picking it up if it was set through an existing user link on app

    window.setTimeout(this.setDelayedTitle, 5000); 

    window.setTimeout(this.showImagesNow, 10);
    
    window.addEventListener("scroll", this.doScrollIsTrue);



  }, 
  beforeUnmount(){
    window.scrollTo({ top: 0 });
    // console.log("UNMOUNTING");
  }

}
</script>

<style>

.actionbtn-div {
  padding-top: clamp(2px, 2.5vh, 24px);
  /* padding-bottom: clamp(2px, 2vh, 18px); */
}
.actionbtn {

  border-radius: 10px;
  border:1px solid #000066;
  position: relative;
  width: 95%;
  height: 90%;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  cursor:pointer;

  font-family: "LT", Helvetica, Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  /* or 117% */

  letter-spacing: 0.35px;

  color: #000066;
  /* Box Shadow // Medium */

  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
}



</style>